import './UnifySetup.css';

// Libraries
import { useState, useEffect, useCallback } from 'react';

// Utils & hooks
import { useQuery, GET_TABLES, GET_TABLE_AND_PROPERTIES } from 'utils/graphql';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import User from 'utils/user.util';

// Components
import Button from 'components/Buttons/Button/Button';
import Dropdown from 'components/Form/Dropdown/Dropdown';
import ContentPanel from 'components/ContentPanel/ContentPanel';
import DataTable from 'components/DataTable/DataTable';
import Slider from 'components/Slider/Slider';
import Loader from 'components/Loader/Loader';
import IconButton from 'components/Buttons/IconButton/IconButton';
import Alert from 'components/Alert/Alert';
import Modal from 'components/Modal/Modal';

// Assets
import { ReactComponent as AddIcon } from 'assets/icons/add_icon.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete_icon.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/exclamation-triangle.svg';

const UnifySetup = () => {
    let { unify_id } = useParams();
    const { state } = useLocation();
    const navigate = useNavigate();
    const [title, setTitle] = useState('Unify Setup');
    const [tables, setTables] = useState([]);
    const [routines, setRoutines] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [tableId, setTableId] = useState();
    const [threshold, setThreshold] = useState(80);
    const [tableSelected, setTableSelected] = useState(false);
    const [tableProperties, setTableProperties] = useState([]);
    const [objectAttributes, setObjectAttributes] = useState([]);
    const [columns, setColumns] = useState([]);
    const [errors, setErrors] = useState();
    const [modalType, setModalType] = useState();
    const [modalIsOpen, setModalIsOpen] = useState(false);

    if (unify_id === 'new') {
        unify_id = null;
    }

    const { data: tablesData } = useQuery(GET_TABLES, {
        variables: {
            offset: 0,
            limit: 99,
        },
        fetchPolicy: 'cache-and-network',
    });

    const getTenantUnifyRoutines = async () => {
        const routines = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/api/unify/tenant/routines`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + User.getToken(),
                },
            }
        )
            .then((res) => res.json())
            .then((res) => {
                return res.routines;
            })
            .catch((err) => {
                console.log(err);
            });
        setRoutines(routines);
    };

    const { loading: tableLoading } = useQuery(GET_TABLE_AND_PROPERTIES, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
        skip: !tableId,
        variables: {
            id: tableId,
        },
        onCompleted: (results) => {
            // filtering out properties that are not Text
            const tableProperties = results.tableSchema.properties.edges
                .map((edge) => edge.node)
                .filter((tp) => tp.type.type === 'Text');
            setTableProperties(tableProperties);
        },
    });

    const validateObjectAttributes = () => {
        setErrors(null);
        const propertyIds = new Set();
        for (const attribute of objectAttributes) {
            if (propertyIds.has(attribute.propertyId)) {
                setErrors([
                    'Attributes must be unique. You cannot have multiple weights set for the same attribute.',
                ]);
                return false;
            }
            propertyIds.add(attribute.propertyId);
        }
        return true;
    };

    const createUnifyRoutine = async () => {
        const success = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/api/unify/tenant/routines`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + User.getToken(),
                },
                body: JSON.stringify({
                    table_id: tableId,
                    threshold,
                    properties: objectAttributes.map((attr) => ({
                        property_id: attr.propertyId,
                        weight: attr.weight,
                    })),
                }),
            }
        )
            .then((res) => res.json())
            .then((res) => {
                return res?.success;
            })
            .catch((err) => {
                console.log(err);
                return false;
            });

        if (success) {
            openModal('createSuccess');
            setTimeout(() => {
                navigate(`/unify`);
            }, 1500);
        } else {
            openModal('createError');
        }
    };

    const updateUnifyRoutine = async () => {
        const success = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/api/unify/tenant/routines/${unify_id}`,
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + User.getToken(),
                },
                body: JSON.stringify({
                    table_id: tableId,
                    threshold,
                    properties: objectAttributes.map((attr) => ({
                        id: attr.id,
                        property_id: attr.propertyId,
                        weight: attr.weight,
                    })),
                }),
            }
        )
            .then((res) => res.json())
            .then((res) => {
                return res?.success;
            })
            .catch((err) => {
                console.log(err);
                return false;
            });

        if (success) {
            openModal('updateSuccess');
            setTimeout(() => {
                navigate(`/unify`);
            }, 1500);
        } else {
            openModal('updateError');
        }
    };

    const save = async () => {
        if (!validateObjectAttributes()) {
            return;
        }
        if (!unify_id) {
            createUnifyRoutine();
        } else {
            updateUnifyRoutine();
        }
    };

    const getColumns = useCallback(() => {
        const addAttribute = () => {
            setObjectAttributes((prevAttributes) => [
                ...prevAttributes,
                { propertyId: tableProperties[0].id, weight: 0.6 },
            ]);
        };
        const handleFormChange = (index, value, property) => {
            setObjectAttributes((prev) => {
                const updatedData = [...prev];
                updatedData[index] = { ...updatedData[index], [property]: value };
                return updatedData;
            });
        };
        return [
            {
                name: 'Attribute',
                sortable: false,
                selector: (row, index) => {
                    return (
                        <Dropdown
                            width="200px"
                            setValue={(e) => handleFormChange(index, e.target.value, 'propertyId')}
                            value={row.propertyId}
                            values={tableProperties.map((tp) => ({
                                value: tp.id,
                                text: tp.property,
                            }))}
                        />
                    );
                },
            },
            {
                name: 'Similarity Weight',
                sortable: false,
                selector: (row, index) => {
                    return (
                        <Slider
                            min={0}
                            max={1}
                            isFloat={true}
                            value={row.weight}
                            onChange={(value) => handleFormChange(index, value, 'weight')}
                        />
                    );
                },
            },
            {
                name: (() => {
                    return (
                        <IconButton onClick={addAttribute} padding="8px">
                            <AddIcon fill="var(--accent-color)" height="24" width="24" />
                        </IconButton>
                    );
                })(),
                grow: 0,
                sortable: false,
                width: 'auto',
                selector: (row, index) => (
                    <IconButton
                        onClick={() => {
                            setObjectAttributes((prev) => {
                                const updatedData = [...prev];
                                updatedData.splice(index, 1);
                                return updatedData;
                            });
                        }}
                        padding="8px"
                    >
                        <DeleteIcon fill="var(--error-color)" height="24" width="24" />
                    </IconButton>
                ),
            },
        ];
    }, [tableProperties]);

    const continueToStep2 = () => {
        setTableSelected(true);
        const selectedTable = tables.find((t) => t.id === tableId);
        setTitle(`Unify - ${selectedTable.name}`);
        setColumns(getColumns());
    };

    const backToStep1 = () => {
        setTableSelected(false);
        setTitle(`Unify`);
    };

    const openModal = (type) => {
        setModalType(type);
        setModalIsOpen(true);
    };

    const getModalOptions = () => {
        switch (modalType) {
            case 'createSuccess':
            case 'updateSuccess':
                return {
                    title: 'Success',
                    content: (
                        <div>
                            <p>
                                Unify routine has been{' '}
                                {modalType.startsWith('create') ? 'created' : 'updated'}.
                            </p>
                        </div>
                    ),
                    width: '250px',
                    textAlign: 'center',
                };
            case 'createError':
            case 'updateError':
                return {
                    title: <ErrorIcon fill="var(--error-color)" width="40" height="40" />,
                    content: (
                        <p>
                            There was an error while{' '}
                            {modalType.startsWith('create') ? 'creating' : 'updating'} the unify
                            routine.
                        </p>
                    ),
                    width: '250px',
                    textAlign: 'center',
                };
            default:
                return {};
        }
    };

    useEffect(() => {
        if (!tablesData || !tablesData.tableSchemaList || (!unify_id && !routines)) return;

        let _tables = tablesData.tableSchemaList.edges
            .filter((edge) => !edge.node.isJunction)
            .map((edge) => edge.node);
        if (!unify_id && routines?.length > 0) {
            const usedTableIds = new Set(routines.map((routine) => routine.table.global_id));
            _tables = _tables.filter((table) => !usedTableIds.has(table.id));
        }
        setTables(_tables);
        if (!unify_id) {
            setTableId(_tables[0]?.id);
        }
        setIsLoading(false);
    }, [tablesData, routines, unify_id]);

    useEffect(() => {
        if (!unify_id && tableProperties && tableProperties.length > 0) {
            setObjectAttributes([
                {
                    propertyId: tableProperties[0].id,
                    weight: 0.6,
                },
            ]);
        }
    }, [tableProperties, unify_id]);

    useEffect(() => {
        if (!unify_id) {
            getTenantUnifyRoutines();
        }
    }, [unify_id]);

    useEffect(() => {
        if (state) {
            setTableId(state.table.global_id);
            setThreshold(state.threshold);
            let objAttributes = [];
            for (const field of state.fields) {
                objAttributes.push({
                    id: field.id,
                    propertyId: field.table_property.global_id,
                    weight: field.weight,
                });
            }
            setObjectAttributes(objAttributes);
        }
    }, [state]);

    return (
        <div className="unify-setup">
            <ContentPanel title={title}>
                {isLoading ? (
                    <div className="loader-container">
                        <Loader />
                    </div>
                ) : !tableSelected ? (
                    <div className="step-1">
                        <p>Select Table</p>
                        <Dropdown
                            value={tableId}
                            values={tables.map((table) => ({
                                text: table.name,
                                value: table.id,
                            }))}
                            width="100%"
                            setValue={(e) => {
                                setTableId(e.target.value);
                            }}
                            readOnly={unify_id}
                        />
                        <p>Minimum Threshold</p>
                        <Slider
                            min={0}
                            max={100}
                            value={threshold}
                            width="100%"
                            onChange={(value) => setThreshold(value)}
                        />
                        <Button className="continue-btn" width="100%" onClick={continueToStep2}>
                            Continue
                        </Button>
                    </div>
                ) : (
                    <div className="step-2">
                        {errors?.length > 0 && (
                            <div className="unify-errors">
                                {errors.map((error, i) => (
                                    <Alert key={i} variant="error">
                                        {error}
                                    </Alert>
                                ))}
                            </div>
                        )}
                        <DataTable
                            columns={columns}
                            data={objectAttributes}
                            noDataComponent="No attributes."
                            pagination={false}
                            highlightOnHover={false}
                            progressPending={tableLoading}
                            progressComponent={<Loader />}
                            className="unify-setup-table"
                            customStyles={{
                                rows: {
                                    style: {
                                        backgroundColor: 'var(--greyscale-color)',
                                    },
                                },
                                table: {
                                    style: {
                                        backgroundColor: 'var(--greyscale-color)',
                                    },
                                },
                            }}
                        />
                        <div className="btn-row">
                            <Button variant="secondary" onClick={backToStep1} width="150px">
                                Back
                            </Button>
                            <Button onClick={save} width="150px">
                                {unify_id ? 'Update' : 'Create'}
                            </Button>
                        </div>
                    </div>
                )}
            </ContentPanel>
            <Modal options={getModalOptions()} isOpen={modalIsOpen} setOpen={setModalIsOpen} />
        </div>
    );
};

export default UnifySetup;
