import './Segmentation.css';

// Libraries
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ucWords } from 'utils/text.util';
import { useNavigate, useLocation } from 'react-router-dom';

// Components
import Search from 'components/Search/Search';
import DataTable from 'components/DataTable/DataTable';
import Modal from 'components/Modal/Modal';
import Loader from 'components/Loader/Loader';
import IconButton from 'components/Buttons/IconButton/IconButton';
import { CurrentPermissionContext } from 'components/AccessWrapper/AccessWrapper';

// Utils
import User from 'utils/user.util';
import { formatToUTCDate, isValidDate } from 'utils/date.util';
import { useFetch } from 'utils/rest/request';
import { appColors } from 'utils/app.util';
import { canCreate, canDelete } from 'utils/permission.util';
import {
    GET_SEGMENTS,
    DELETE_SEGMENT,
    GET_SEGMENTATION_COUNT,
    useMutation,
    useLazyQuery,
    useQuery,
} from 'utils/graphql';

// Assets
import { ReactComponent as AddIcon } from 'assets/icons/add_icon.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete_icon.svg';
import { ReactComponent as PlayIcon } from 'assets/icons/play_icon_24.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/download_icon_24.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/exclamation-triangle.svg';

function Segmentation() {
    const { permissionSet } = useContext(CurrentPermissionContext);
    let navigate = useNavigate();
    const location = useLocation();
    const [filter, setFilter] = useState('');
    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalType, setModalType] = useState('delete');
    const [modalFlows, setModalFlows] = useState([]);
    const [modalSelectedSegment, setModalSelectedSegment] = useState();
    const [segmentToDelete, setSegmentToDelete] = useState();
    const [columns, setColumns] = useState([]);
    const [loading, setLoading] = useState(true);
    const [tableData, setTableData] = useState();
    const [perPage, setPerPage] = useState(15);
    const [page, setPage] = useState(1);

    const { data: count, refetch: refreshCount } = useQuery(GET_SEGMENTATION_COUNT, {
        variables: { isConsent: false, name: filter },
    });

    const [getSegments, { data: segmentationData }] = useLazyQuery(GET_SEGMENTS, {
        fetchPolicy: 'no-cache',
        skip: loading,
        variables: {
            isConsent: false,
            offset: 0,
            limit: perPage,
            orderBy: 'modified_at',
            orderByDirection: 'DESC',
        },
        onCompleted: (data) => {
            refreshCount();
            setTableData(data);
            setColumns(getColumns());
            setLoading(false);
        },
    });

    const {
        data: apps,
        error: connected_apps_error,
        loading: apps_loading,
    } = useFetch('/api/connected_apps', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
        timeout: 3000,
    });

    const [deleteSegment] = useMutation(DELETE_SEGMENT);

    const getFlows = async (segmentList = [], appList = []) => {
        const _updated = await Promise.all(
            segmentList.segmentationList.edges?.map(async (edge) => {
                const segment = edge.node;

                // Fetch routines for segment
                const response = await fetch(
                    `${process.env.REACT_APP_BACKEND_URL}/api/connected_apps/tenant/routines?source_id=${segment.id}`,
                    {
                        method: 'GET',
                        headers: {
                            Authorization: 'Bearer ' + User.getToken(),
                            'Content-Type': 'application/json',
                        },
                    }
                ).then((res) => res.json());
                const routines = response?.rows || [];

                const _flows = [];
                // Sort routines by segment and app
                for (const app of appList) {
                    const _routines = routines?.filter(
                        (routine) => routine?.connection.app.id == app.id
                    );

                    if (_routines.length > 0) {
                        _flows.push({
                            app: app,
                            routines: _routines,
                        });
                    }
                }

                return {
                    node: {
                        ...segment,
                        flows: _flows,
                    },
                };
            })
        );

        setTableData({ segmentationList: { edges: _updated } });
    };

    const handlePageChange = (page) => {
        setPage(page);
    };

    const handlePerRowsChange = (newPerPage) => {
        setPerPage(newPerPage);
    };

    const handleSearch = useCallback(() => {
        setLoading(true);

        window.history.replaceState(
            null,
            '',
            `?query=${encodeURIComponent(filter)}&page=${page}&perPage=${perPage}`
        );

        getSegments({
            variables: { search: filter, offset: perPage * (page - 1), limit: perPage },
        });
    }, [getSegments, filter, page, perPage]);

    const handleNewClick = () => {
        navigate(`/segmentation/new`);
    };

    const handleNewFlow = (id) => {
        navigate(`/segmentation/flow/new/${id}`);
    };

    const handleDownload = (id) => {
        navigate(`/segmentation/download/${id}`);
    };

    const handleSegmentSelect = (row) => {
        navigate(`/segmentation/${row.id}`);
    };

    const getColumns = () => [
        {
            name: 'Name',
            sortable: false,
            selector: (row) => row.name,
            format: (row) => ucWords(row.name),
        },
        // {
        //     name: 'Count',
        //     sortable: true,
        //     selector: (row) => (row.count != null ? row.count : 'Loading...'),
        // },
        {
            name: 'Consent',
            sortable: false,
            selector: (row) => row?.consent?.name,
        },
        {
            name: 'Modified',
            sortable: false,
            selector: (row) => {
                const last_audit_date = new Date(
                    Math.max(...row?.audits.edges.map((audit) => new Date(audit.node.createdAt)))
                );
                const modified_date = new Date(last_audit_date?.toString() || row.created_at);
                if (!isValidDate(modified_date)) return '';
                return formatToUTCDate(modified_date);
            },
        },
        {
            name: <div style={{ paddingLeft: '8px' }}>Flows</div>,
            sortable: false,
            selector: (row) => {
                if (row?.flows?.length > 0) {
                    const logos = row.flows.map((_app, i) => {
                        const opacity = _app.app.status != 'active' ? 0.3 : 1;
                        return (
                            <IconButton
                                key={i}
                                onClick={() => {
                                    // Todo: error msg
                                    if (_app.app.status != 'active') return;
                                    if (_app.routines.length === 1) {
                                        const routine = _app.routines[0];
                                        navigate(`/segmentation/flow/${routine.id}/${row.id}`);
                                    } else if (_app.routines.length > 1) {
                                        setModalSelectedSegment(row.id);
                                        setModalFlows(_app.routines);
                                        openModal('flows');
                                    }
                                }}
                                padding="8px"
                            >
                                <div style={{ height: '24px', width: '24px', opacity }}>
                                    {renderLogo(_app.app, i)}
                                </div>
                            </IconButton>
                        );
                    });

                    return <div style={{ display: 'flex' }}>{logos}</div>;
                }

                return <></>;
            },
        },
        {
            name: canCreate(permissionSet) && (
                <IconButton onClick={handleNewClick} padding="8px">
                    <AddIcon fill="var(--accent-color)" height="24" width="24" />
                </IconButton>
            ),
            sortable: false,
            width: '152px',
            right: true,
            selector: (row) => {
                return (
                    <div className="action-btns">
                        <IconButton
                            onClick={() => {
                                if (apps_loading) {
                                    return openModal('flowsLoading');
                                } else if (connected_apps_error) {
                                    return openModal('flowsLoadingError');
                                }
                                handleNewFlow(row.id);
                            }}
                            padding="8px"
                            tooltip="Create Flow"
                        >
                            <PlayIcon
                                fill={
                                    apps_loading || connected_apps_error
                                        ? 'var(--greyscale-highlight-color)'
                                        : 'var(--font-color)'
                                }
                                height="24"
                                width="24"
                            />
                        </IconButton>

                        <IconButton
                            onClick={() => handleDownload(row.id)}
                            padding="8px"
                            tooltip="Export CSV"
                        >
                            <DownloadIcon fill="var(--font-color)" height="24" width="24" />
                        </IconButton>
                        {canDelete(permissionSet) && (
                            <IconButton
                                onClick={() => {
                                    setSegmentToDelete(row);
                                    openModal('delete');
                                }}
                                padding="8px"
                            >
                                <DeleteIcon fill="var(--error-color)" height="24" width="24" />
                            </IconButton>
                        )}
                    </div>
                );
            },
        },
    ];

    const openModal = (type) => {
        setModalType(type);
        setIsOpen(true);
    };

    const getModalOptions = (type) => {
        switch (type) {
            case 'delete':
                return {
                    type: 'confirmation',
                    title: 'Warning',
                    content: (
                        <div>
                            <p>Are you sure you want to delete this segment? </p>
                            <b>{segmentToDelete?.name}</b>
                        </div>
                    ),
                    confirmBtnText: 'Yes',
                    cancelBtnText: 'No',
                    width: '250px',
                    textAlign: 'center',
                    confirmationBtnAction: async () => {
                        deleteSegment({
                            variables: { id: segmentToDelete.id },
                            onCompleted: (data) => {
                                if (data.deleteSegmentation) {
                                    getSegments({
                                        variables: {
                                            search: filter,
                                            offset: perPage * (page - 1),
                                            limit: perPage,
                                        },
                                    });
                                    openModal('deleted');
                                } else {
                                    openModal('deleteError');
                                }
                            },
                        });
                    },
                };
            case 'deleted':
                return {
                    title: 'Success',
                    content: (
                        <div>
                            <p>Segment has been deleted.</p>
                        </div>
                    ),
                    width: '250px',
                    textAlign: 'center',
                    confirmationBtnAction: () => navigate('/segmentation'),
                };
            case 'deleteError':
                return {
                    title: <WarningIcon fill="var(--error-color)" width="40" height="40" />,
                    content: (
                        <div>
                            <p>Segment could not be deleted.</p>
                        </div>
                    ),
                    width: '250px',
                    textAlign: 'center',
                };
            case 'flowsLoading':
                return {
                    title: 'Loading!',
                    content: (
                        <div>
                            <p>Hold on a sec while we load.</p>
                        </div>
                    ),
                    width: '250px',
                    textAlign: 'center',
                };
            case 'flowsLoadingError':
                return {
                    title: <WarningIcon fill="var(--error-color)" width="40" height="40" />,
                    content: (
                        <div>
                            <p>Creating flows is currently unavailable.</p>
                            <p>Please try again later.</p>
                        </div>
                    ),
                    width: '250px',
                    textAlign: 'center',
                };
            case 'flows':
                return {
                    title: 'Select Flow',
                    content: (
                        <ul className="segmentation-modal-flows">
                            {modalFlows?.map((flow, i) => {
                                return (
                                    <li
                                        key={i}
                                        onClick={() =>
                                            navigate(
                                                `/segmentation/flow/${flow.id}/${modalSelectedSegment}`
                                            )
                                        }
                                    >
                                        {flow.name}
                                    </li>
                                );
                            })}
                        </ul>
                    ),
                    width: '250px',
                    textAlign: 'center',
                };
            default:
                return {};
        }
    };

    const renderLogo = (app, index) => {
        const name = app.name.substr(0, 1).toUpperCase() + app.name.substr(1, 1).toLowerCase();
        const logo = app.connection_dependencies.find((e) => e.field === 'LOGO')?.value;

        if (logo) {
            // The nested app-icon is necessary when using dangerouslySetInnerHTML because it
            // cannot have any children
            return (
                <div className="app-icon">
                    <div className="app-icon" dangerouslySetInnerHTML={{ __html: logo }}></div>
                </div>
            );
        }

        return (
            <div
                className="app-icon"
                style={{
                    backgroundColor: appColors[index].bg,
                    color: appColors[index].font,
                }}
            >
                {name}
            </div>
        );
    };

    /**
     * Once segment list and apps have been loaded,
     * fetch list of flows for each segment.
     */
    useEffect(() => {
        if (!segmentationData || !apps || loading) return;
        getFlows(segmentationData, apps?.rows).then(() => {
            setColumns(getColumns());
        });
    }, [segmentationData, apps, loading]);

    /**
     * Fail safe for when CI platform connection error.
     */
    useEffect(() => {
        if (connected_apps_error) {
            setColumns(getColumns());
            setLoading(false);
        }
    }, [connected_apps_error]);

    /**
     * Execute initial blank search if nothing in url query params
     */
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const query = queryParams.get('query') || '';
        if (!query) {
            getSegments();
        }
    }, []);

    /**
     * Update search if any of the dependencies change
     */
    useEffect(() => {
        handleSearch();
    }, [filter, page, perPage]);

    /**
     * Execute search if in url query params
     */
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const query = queryParams.get('query') || '';
        const _page = parseInt(queryParams.get('page') || page);
        const _perPage = parseInt(queryParams.get('perPage') || perPage);

        setFilter(query);
        setPage(_page);
        setPerPage(_perPage);
    }, []);

    return (
        <div className="segmentation-list">
            <div className="segmentation-list-header">
                <Search handler={(search) => setFilter(search)} />
            </div>
            <DataTable
                columns={columns}
                data={tableData?.segmentationList.edges.map((row) => {
                    return row.node;
                })}
                noDataComponent="You don't have any segments."
                pagination={true}
                paginationServer={true}
                paginationTotalRows={count?.segmentationListCount || 0}
                paginationPerPage={perPage}
                paginationDefaultPage={page}
                progressPending={loading}
                progressComponent={<Loader />}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                defaultSortFieldId={1}
                highlightOnHover={true}
                onRowClicked={handleSegmentSelect}
                persistTableHead={true}
                selectableRows={false}
            />
            <Modal options={getModalOptions(modalType)} isOpen={modalIsOpen} setOpen={setIsOpen} />
        </div>
    );
}

export default Segmentation;
