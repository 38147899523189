import { gql } from './apollo';

export const GET_UNIFIED_RESULTS_FOR_TABLE = gql`
    query ($tableId: ID!, $limit: Int = 100, $offset: Int = 0) {
        unifiedResultsForTable(tableId: $tableId, limit: $limit, offset: $offset)
    }
`;

export const GET_LATEST_PROPERTY_DATES_FOR_OBJECTS = gql`
    query ($ids: [String]!) {
        latestPropertyDatesForObjects(ids: $ids)
    }
`;

export const UNIFY_OBJECTS = gql`
    mutation ($unifiedResultsIds: [String]!) {
        unifyObjects(unifiedResultsIds: $unifiedResultsIds)
    }
`;

export const IGNORE_UNIFIED_RESULTS = gql`
    mutation ($unifiedResultsId: String!) {
        ignoreUnifiedResults(unifiedResultsId: $unifiedResultsId)
    }
`;

export const GET_UNIFIED_RESULTS_COUNT = gql`
    query {
        unifiedResultsCount
    }
`;
