import './Pill.css';

// Libraries
import PropTypes from 'prop-types';

const Pill = ({
    children,
    variant = 'secondary',
    onClick,
    className = '',
    width = '100px',
    height = 'auto',
    minHeight = '45px',
}) => {
    return (
        <span
            className={`pill ${variant} ${className}`}
            style={{ width, height, minHeight, cursor: onClick ? 'pointer' : 'default' }}
            onClick={onClick ? onClick : undefined}
        >
            {children}
        </span>
    );
};

Pill.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    variant: PropTypes.oneOf([
        'primary',
        'danger',
        'secondary',
        'accent',
        'success',
        'primary-light',
    ]),
    onClick: PropTypes.func,
    width: PropTypes.string,
    height: PropTypes.string,
    minHeight: PropTypes.string,
};

export default Pill;
